<template>
  <div class="card">
    <div class="card-body">
      <div class="row success">
        <div class="col-xl-8">
          <h5 style="color: blue; margin-bottom: 30px">
            <span style="margin-right: 30px"> {{ $t("bulletin.notes") }}</span>
            <a-tooltip
              placement="right"
              v-if="selectedClasse && selectedTrimester"
            >
              <template slot="title">
                {{
                  isValidatedBulletin()
                    ? $t("bulletin.hideBulletin")
                    : $t("bulletin.validateBulletin")
                }}
              </template>
              <span>
                <a-button
                  :type="isValidatedBulletin() ? 'primary' : 'danger'"
                  @click="confirmeBulletinValidation()"
                >
                  {{
                    isValidatedBulletin()
                      ? $t("bulletin.cancelValidation")
                      : $t("bulletin.validatedClassroom")
                  }}
                </a-button>
              </span>
            </a-tooltip>
          </h5>
          <label>{{ $t("bulletin.listeClasses") }}</label>
          <a-select
            show-search
            :placeholder="$t('emploi.listeClasses')"
            option-filter-prop="children"
            :filter-option="filterOption"
            style="width: 150px; margin-left: 15px"
            @change="
              (val) => {
                handleClassChange('classe', val);
              }
            "
          >
            <a-select-option value="all"> --- </a-select-option>
            <a-select-option
              v-for="classe in classes"
              :key="classe._id"
              :value="classe._id"
            >
              {{ classe.name }}
            </a-select-option>
          </a-select>

          <label style="margin-right: 5px; margin-left: 15px">{{
            this.$t("bulletin.matieres")
          }}</label>
          <a-select
            :placeholder="$t('scolarite.listeMatieres')"
            style="width: 180px; margin-left: 15px"
            :loading="subjectLoading"
            v-model="selectedSubject"
            @change="
              (val) => {
                this.selectedSubject = val;

                this.listModules.forEach((module) => {
                  module.subjects.forEach((subject) => {
                    if (subject._id == val)
                      this.selectedSubjectName = subject.name;
                  });
                });
              }
            "
          >
            <a-select-opt-group v-for="modul in listModules" :key="modul._id">
              <span slot="label"><a-icon type="user" />{{ modul.name }}</span>
              <a-select-option
                v-for="subject in modul.subjects"
                :key="subject._id"
                :value="subject._id"
              >
                {{ subject.name }}
              </a-select-option>
            </a-select-opt-group>
          </a-select>

          <label style="margin-right: 5px; margin-left: 15px">{{
            this.$t("emploi.trimestre")
          }}</label>
          <a-select
            :placeholder="$t('paiement.selectionnerMois')"
            style="width: 50px; margin-left: 15px"
            @change="
              (val) => {
                this.selectedTrimester = val;
              }
            "
            default-value="1"
          >
            <a-select-option
              v-for="trimester in ['1', '2', '3']"
              :key="trimester"
              :value="trimester"
            >
              {{ trimester }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-xl-4">
          <div
            class="card"
            style="padding: 15px"
            v-if="classroomMarksStats && classroomMarksStats.classrooms"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="pr-3">
                <h2 class="font-size-18 font-weight-bold mb-1 text-dark">
                  {{ $t("bulletin.classesActives") }}
                </h2>
                <p
                  class="font-size-15 mb-3"
                  v-if="classroomMarksStats.classrooms.length !== 0"
                >
                  {{ $t("bulletin.nombreClassesNonNote") }}
                </p>
                <p class="font-size-15 mb-3" v-else>
                  {{ $t("bulletin.tousClassesSontNotes") }}
                </p>
              </div>
              <div
                v-if="classroomMarksStats.classrooms.length !== 0"
                class="text-success font-weight-bold font-size-24"
              >
                {{ classroomMarksStats.classrooms.length }}
                {{ $t("bulletin.classes") }}
              </div>

              <div class="text-success font-weight-bold font-size-24" v-else>
                <a-icon type="check" />
              </div>
            </div>

            <div
              class="d-flex text-gray-5 justify-content-right font-size-14"
              v-if="classroomMarksStats.classrooms.length !== 0"
            >
              <a-button @click="visibleClassnameList = true">{{
                $t("action.afficher")
              }}</a-button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-8">
          <div class="card" v-if="selectedSubject && selectedClasse">
            <a-table
              @change="tableChanged"
              :loading="tableLoading"
              :pagination="true"
              :data-source="activeData"
              :columns="columns"
            >
              <template slot="mark" slot-scope="text, record">
                <marks
                  :id="record._id"
                  :subject="selectedSubject"
                  :trimester="selectedTrimester"
                  :classroom="selectedClasse"
                  :ref="`markRefs${record._id}`"
                />
              </template>

              <div
                slot="filterDropdown"
                slot-scope="{
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                  column,
                }"
                style="padding: 8px"
              >
                <a-input
                  v-ant-ref="(c) => (searchInput = c)"
                  :placeholder="`${$t('personnel.chercher')} ${column.title}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block"
                  @change="
                    (e) =>
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                  "
                  @pressEnter="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                />
                <a-button
                  type="primary"
                  icon="search"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                  >{{ $t("paiement.chercher") }}</a-button
                >
                <a-button
                  size="small"
                  style="width: 90px"
                  @click="() => handleReset(clearFilters)"
                  >{{ $t("action.reinitialiser") }}</a-button
                >
              </div>
              <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
              <template
                slot="customRender"
                slot-scope="text, record, index, column"
              >
                <span v-if="searchText && searchedColumn === column.dataIndex">
                  <template
                    v-for="(fragment, i) in text
                      .toString()
                      .split(
                        new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                      )"
                  >
                    <mark
                      v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                      :key="i"
                      class="highlight"
                      >{{ fragment }}</mark
                    >
                    <template v-else>{{ fragment }}</template>
                  </template>
                </span>
                <template v-else>{{ text }}</template>
              </template>
            </a-table>
          </div>
          <div
            class="row"
            v-if="selectedClasse && selectedSubject && activeData.length !== 0"
          >
            <div class="col-xl-7">{{ $t("bulletin.toutMatieres") }}</div>
            <div class="col-xl-3">
              <a-button
                style="
                  margin-right: 5px;
                  margin-left: 20px;
                  margin-bottom: 10px;
                  background: #8bc34a !important;
                  border-color: #8bc34a !important;
                "
                type="primary"
                @click="saveAll()"
              >
                {{ $t("bulletin.enregistrerTout") }}<a-icon type="check" />
              </a-button>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div
            class="card"
            style="padding: 15px"
            v-if="selectedClasse && classroomMarksStats.subjects"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="pr-3">
                <h2 class="font-size-18 font-weight-bold mb-1 text-dark">
                  {{ selectedClassName }}
                </h2>
                <p
                  class="font-size-15 mb-3"
                  v-if="
                    classroomMarksStats.subjects[selectedClasse] &&
                    classroomMarksStats.subjects[selectedClasse].length !== 0
                  "
                >
                  {{ $t("bulletin.nombreMatieresNonNote") }}
                </p>
                <p class="font-size-15 mb-3" v-else>
                  {{ $t("bulletin.tousMatieresSontNotes") }}
                </p>
              </div>
              <div
                class="text-success font-weight-bold font-size-24"
                v-if="
                  classroomMarksStats.subjects[selectedClasse] &&
                  classroomMarksStats.subjects[selectedClasse].length !== 0
                "
              >
                {{ classroomMarksStats.subjects[selectedClasse].length }}
                matières
              </div>
              <div class="text-success font-weight-bold font-size-24" v-else>
                <a-icon type="check" />
              </div>
            </div>

            <div
              class="d-flex text-gray-5 justify-content-right font-size-16"
              v-if="classroomMarksStats.subjects[selectedClasse]"
            >
              <a-button @click="visibleSubjectList = true">{{
                $t("action.afficher")
              }}</a-button>
            </div>
          </div>

          <a-divider
            v-if="selectedClasse && selectedSubject && activeData.length !== 0"
            ><span class="font-size-18">{{
              selectedSubjectName
            }}</span></a-divider
          >

          <div
            class="card"
            v-if="selectedSubject && selectedClasse && activeData.length !== 0"
          >
            <subjectChart
              :subject="selectedSubject"
              :classroom="selectedClasse"
            />
          </div>
        </div>
      </div>
    </div>
    <a-modal
      v-model="visibleClassnameList"
      :title="$t('bulletin.listeClassesNonNote')"
      :width="500"
      :height="700"
      :key="'classnameList'"
    >
      <div :style="{ overflowY: 'scroll' }">
        <div
          v-for="classname in classroomMarksStats.classrooms"
          :key="classname"
        >
          {{ classname }}
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model="visibleSubjectList"
      :title="$t('bulletin.listeMatiereNonNote')"
      :width="500"
      :height="700"
      :key="'subjectsListNotMarked'"
    >
      <div
        :style="{ overflowY: 'scroll' }"
        v-if="selectedClasse && classroomMarksStats.subjects"
      >
        <div
          v-for="name in classroomMarksStats.subjects[selectedClasse]"
          :key="name"
        >
          {{ name }}
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";
import marks from "./components/marks";
import subjectChart from "./components/subjectChart";
import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { notification, Modal } from "ant-design-vue";
export default {
  computed: mapState(["settings", "user"]),
  components: { marks: marks, subjectChart },
  async created() {
    this.tableLoading = true;

    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            level: 1,
            bulletinSpec: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data));

    this.tableLoading = false;
    this.filtredTable = this.activeData;

    this.schoolType = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0].type;
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });

    apiClient
      .get("/mark/classroomMarksStats/" + this.selectedTrimester + "/specific")
      .then(({ data }) => {
        this.classroomMarksStats = data;
      });
  },
  data() {
    return {
      visibleClassnameList: false,
      visibleSubjectList: false,
      schoolType: null,
      filtredTable: [],
      classroomMarksStats: {},
      selectedSubject: this.$t("scolarite.listeMatieres"),
      selectedSubjectName: "",
      selectedClassName: "",

      subjectLoading: false,
      selectedTrimester: "1",
      selectedClasse: null,
      tableLoading: false,
      activeData: [],
      markRefs: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [
        {
          title: this.$t("inscription.nom"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: {
            customRender: "fullName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.fullName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("bulletin.note"),
          dataIndex: "mark",
          scopedSlots: { customRender: "mark" },
        },
      ],
      listModules: [],
      selectedLevel: null,
    };
  },
  methods: {
    confirmeBulletinValidation() {
      const content = this.isValidatedBulletin()
        ? this.$t("bulletin.hideBulletin")
        : this.$t("bulletin.validateBulletin");
      this.$confirm({
        title: this.$t("all.sureTo"),
        content: (h) => <div style="color:red"> {content} </div>,
        onOk: () => {
          //console.log("OK");
          this.validateBulletin();
        },
        onCancel: () => {
          //console.log("Cancel");
        },
        class: "test",
      });
    },
    isValidatedBulletin() {
      if (this.selectedClasse) {
        const bulletin = this.classes.find(
          (item) => item._id === this.selectedClasse
        ).bulletinSpec || { sem1: false, sem2: false, sem3: false };
        if (this.selectedTrimester == 1 && bulletin.sem1 == true) return true;
        else if (this.selectedTrimester == 2 && bulletin.sem2 == true)
          return true;
        else if (this.selectedTrimester == 3 && bulletin.sem3 == true)
          return true;
      }
      return false;
    },
    validateBulletin() {
      const isValidated = this.isValidatedBulletin();
      const bulletin = this.classes.find(
        (item) => item._id === this.selectedClasse
      ).bulletinSpec || { sem1: false, sem2: false, sem3: false };
      if (this.selectedTrimester == 1) bulletin.sem1 = !bulletin.sem1;
      if (this.selectedTrimester == 2) bulletin.sem2 = !bulletin.sem2;
      if (this.selectedTrimester == 3) bulletin.sem3 = !bulletin.sem3;
      apiClient
        .patch("/classrooms/" + this.selectedClasse, {
          data: {
            bulletinSpec: bulletin,
          },
          bulletin: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            avaliable: !isValidated,
            type: "specific",
          },
        })
        .then(() => {
          this.classes = this.classes.map((item) => {
            if (item._id === this.selectedClasse) item.bulletinSpec = bulletin;
            return item;
          });

          if (!isValidated)
            this.$message.success(
              this.$t("bulletin.validated", { name: this.selectedClassName })
            );
          else
            this.$message.success(
              this.$t("bulletin.unvalidated", { name: this.selectedClassName })
            );
        });
    },
    saveAll() {
      let thereEmpty = false;
      this.activeData.forEach((student) => {
        const el = this.$refs[`markRefs${student._id}`];
        if (el && el.edit && !el.newMark) {
          thereEmpty = true;
          return this.$message.error(this.$t("error.impoEnregistrerVide"));
        }
      });

      if (!thereEmpty) {
        this.activeData.forEach((student) => {
          const el = this.$refs[`markRefs${student._id}`];
          if (el && !el.mark && el.newMark) {
            el.addMark(false);
          }
          if (el && el.edit)
            if (el.newMark) {
              el.updateMark(false);
            }
        });

        return this.$message.success(this.$t("success.noteToutesEnregistre"));
      }
    },
    async getModules(level) {
      await apiClient
        .post("/moduleSpecific/filter", {
          query: { status: "active", level: level },
        })
        .then((res) => {
          this.listModules = res.data;
          if (res.data.length == 0)
            this.$message.warning(this.$t("error.aucModule"));
          this.subjectLoading = false;
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
          this.subjectLoading = false;
        });
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async handleClassChange(on, value) {
      this.markRefs = [];
      this.selectedSubject = null;
      this.selectedSubject = "";
      this.subjectLoading = true;
      this.selectedClasse = value;
      this.selectedClassName = this.classes.find(
        (item) => item._id === value
      ).name;
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] = value;
      await apiClient
        .post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                classroom: {
                  _id: 1,
                  name: 1,
                },
              },
            },
          ],
        })
        .then(({ data }) => {
          this.rowData = [];
          data.map((elem) => {
            try {
              this.rowData.push({
                _id: elem._id,
                key: elem._id,
                fullName: elem.firstName + " " + elem.lastName,
                classroom: elem.classroom[0].name,
                classroom_id: elem.classroom[0]._id,
                gender: elem.gender,
              });
            } catch {}
          });
          this.activeData = this.rowData;
          this.tableLoading = false;
          this.filtredTable = this.activeData;
        });
      //get modules
      this.getModules(this.selectedLevel);
    },

    //generate bulletin pdf
    async convert(record) {
      let marks = [];
      let marksMaxMin = [];
      let missingMarks = [];
      await apiClient
        .get(
          "/mark/maxmin/" + this.selectedClasse + "/" + this.selectedTrimester
        )
        .then((res) => (marksMaxMin = res.data));
      await apiClient
        .post("/mark/filter", {
          query: {
            student: record._id,
            trimester: Number(this.selectedTrimester),
          },
          aggregation: [
            {
              $lookup: {
                from: "subjects",
                localField: "subject",
                foreignField: "_id",
                as: "subject",
              },
            },
            {
              $lookup: {
                from: "students",
                localField: "student",
                foreignField: "_id",
                as: "student",
              },
            },
            {
              $project: {
                _id: 1,
                type: 1,
                subject: {
                  _id: 1,
                  name: 1,
                },
                student: {
                  firstName: 1,
                  lastName: 1,
                },
                mark: 1,
                trimester: 1,
              },
            },
            {
              $match: {
                type: {
                  $in: ["one"],
                },
              },
            },
          ],
        })
        .then((res) => (marks = res.data));

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      var doc = new JsPDF();
      doc.setFont("Amiri");
      doc.setFontSize(15);

      var className = this.classes.find(
        (el) => el._id == this.selectedClasse
      ).name;

      doc.setFontSize(19);
      //pdf header
      let title = "";
      switch (this.selectedTrimester) {
        case "1":
          title = "بطاقة الأعداد - الثلاثي الأول";
          break;
        case "2":
          title = "دفتر الأعداد - الثلاثي الثاني";
          break;
        case "3":
          title = "دفتر الأعداد - الثلاثي الثالث";
          break;
      }

      doc.text(70, 30, title);
      let studentLabel =
        record.gender === "male" ? " : التلميذ" : " : التلميذة";
      doc.autoTable({
        theme: "grid",
        styles: {
          font: "Amiri",
          halign: "right",
          fontSize: 13,
        },
        headStyles: {
          lineWidth: 0.01,
          fillColor: "#ffffff",
          lineColor: [0, 0, 0, 0],
          textColor: "#000000",
        },
        bodyStyles: {
          lineColor: [0, 0, 0, 0],
        },
        head: [[className, " : القسم", record.fullName, studentLabel]],
        margin: { top: 40 },
      });
      doc.setFontSize(15);
      doc.addImage(imgData, "JPEG", 12, 12, 20, 20);
      let moyTrimester = 0;
      // end pdf header
      let sumCoef = 0;

      var erreur = false;
      this.listModules.forEach((m) => {
        let arr = [];

        let moyModule = 0;
        let totalSubjects = m.subjects.length; //to calculate moy module
        let totalCoefs = 0;
        let array = [];
        let headerArray = [
          [
            {
              content: m.name,
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "#146AB3",
                fontSize: 15,
                textColor: "#ffffff",
              },
            },
          ],
          [
            "أدنى عدد بالقسم",
            "أعلى عدد بالقسم",
            "معدل المجال",
            "20/ العدد",
            {
              content: "المادة",
              styles: {
                halign: "center",
                fillColor: "#146AB3",
                fontSize: 14,
                textColor: "#ffffff",
              },
            },
          ],
        ];
        sumCoef += m.coef;

        m.subjects.forEach((c) => {
          let subjectCoef = m.subjectsCoef
            ? m.subjectsCoef[c._id]
              ? m.subjectsCoef[c._id]
              : 1
            : 1;
          arr[1] = {
            content: c.name,
            styles: {
              halign: "center",
              fillColor: "#146AB3",
              fontSize: 14,
              textColor: "#ffffff",
            },
          };
          let studentMark = marks.find((el) => el.subject[0]._id == c._id);
          if (studentMark) {
            arr[2] = studentMark.mark;
            moyModule += studentMark.mark * subjectCoef;
            totalCoefs += subjectCoef;
          } else {
            arr[2] = "--";
            totalSubjects -= 1;
            missingMarks.push(c.name);
            // erreur = true;
          }
          let subjectMaxMin = marksMaxMin.find((el) => el._id == c._id);

          //best mark
          arr[3] = subjectMaxMin ? subjectMaxMin.max : "--";
          //lowest mark
          arr[4] = subjectMaxMin ? subjectMaxMin.min : "--";
          arr = arr.reverse();
          array.push(arr);
          arr = [];
        });
        //fix jspdf rowSpan shifting
        array[0][4] = array[0][3];
        array[0][3] = array[0][2];
        //end shifting
        moyModule = moyModule / totalCoefs;
        moyTrimester += moyModule * m.coef;
        array[0][2] = {
          content: isNaN(moyModule) ? "--" : moyModule.toFixed(2),
          rowSpan: array.length,
          styles: { halign: "center", valign: "center" },
        };
        doc.autoTable({
          theme: "grid",
          styles: { font: "Amiri", halign: "center", fontSize: 13 },
          head: headerArray,
          body: array,
          margin: { top: 65 },
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 10;
          },
          columnStyles: {
            0: { cellWidth: 25 },
            1: { cellWidth: 25 },
            2: { cellWidth: 20 },
            4: { cellWidth: 47 },

            // etc
          },
          headStyles: {
            halign: "center",
            fillColor: "#A4C2CC",
            fontSize: 13,
            fontStyle: "bold",
            textColor: "#146AB3",
          },
        });
      });
      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      moyTrimester = isNaN(moyTrimester)
        ? "--"
        : (moyTrimester / sumCoef).toFixed(2);
      doc.text(100, finalY + 20, "معدل الثلاثي");
      doc.text(70, finalY + 20, moyTrimester);

      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //
      //end page numbering
      Modal.confirm({
        title: this.$t("bulletin.confirmPrintMatieres", {
          count: missingMarks.length,
        }),
        content: (h) => {
          let list = [];
          missingMarks.forEach((s) => {
            list.push(h("li", s));
          });
          return h("ul", list);
        },
        okText: this.$t("bulletin.generateAnyway"),
        okType: "danger",
        cancelText: this.$t("action.annuler"),
        onOk() {
          doc.save("Bulletin " + record.fullName + ".pdf");
        },
        onCancel() {},
      });
      //
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.success .ant-btn-danger {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}
</style>
